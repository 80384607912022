export const auth_request = state => {
    state.status = "loading";
};

export const auth_success = (state, token, tokenExpirationDate, user) => {
    state.status = "success";
    state.token = token;
    state.tokenExpirationDate = tokenExpirationDate;
    state.user = JSON.stringify(user);
};

export const auth_error = state => {
    state.status = "error";
};

export const logout = state => {
    state.status = "";
    state.token = "";
    state.tokenExpirationDate = "";
    state.user = "";
};

export default {
    auth_request,
    auth_success,
    auth_error,
    logout
};

import Vue from 'vue';
import App from './App.vue';
import './plugins/vuex'
import './plugins/axios'
import store from './store'
import router from './router'
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app');

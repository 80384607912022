import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'
import actions from "./actions"
import mutations from "./mutations"
import getters from "./getters"

const state = {
    status: "",
    token: "",
    tokenExpirationDate: "",
    user: {}
};

const vuexStorage = new VuexPersist({
  key: 'nuform',
  storage: window.localStorage
})

export default new Vuex.Store({
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters,
    modules: {},
    plugins: [vuexStorage.plugin]
})

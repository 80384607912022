export default [
    {
        path: "/",
        redirect: "home"
    },
    {
        path: '/home',
        name: 'home',
        component: () => import('../views/Home.vue'),
        meta: {
            guest: false,
            requiresAuth: true
        }
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/Login.vue'),
        meta: {
            guest: true,
            requiresAuth: false
        }
    },
    {
        path: "/profile",
        name: "profile",
        component: () => import('../views/Profile.vue'),
        meta: {
            guest: false,
            requiresAuth: true
        }
    },
    {
        path: "/admin",
        name: "admin",
        component: () => import('../views/Admin.vue'),
        meta: {
            guest: false,
            requiresAuth: true
        }
    },
    {
        path: '/*',
        redirect: '/home'
    }
]

import axios from "axios";

export const login = ({ commit }, user) => {
    return new Promise((resolve, reject) => {
        commit("auth_request");
        axios({
            url: "/users/authenticate",
            data: user,
            method: "POST"
        })
            .then(resp => {
                const responseData = resp.data;
                const token = responseData.token;
                const tokenExpirationDate = responseData.tokenExpirationDate
                var user = {
                    id: responseData.id,
                    username: responseData.username,
                    firstName: responseData.firstName,
                    lastName: responseData.lastName,
                    email: responseData.email,
                    roleId: responseData.roleId
                };

                localStorage.setItem("token", token);
                localStorage.setItem("tokenExpirationDate", tokenExpirationDate);
                localStorage.setItem("user", JSON.stringify(user));
                localStorage.setItem("userId", responseData.id);
                localStorage.setItem("userRoleId", responseData.roleId);
                axios.defaults.headers.common["Authorization"] = "Bearer " + token;
                commit("auth_success", token, tokenExpirationDate, user);
                resolve(resp);
            })
            .catch(err => {
                commit("auth_error");
                localStorage.removeItem("token");
                localStorage.removeItem("tokenExpirationDate");
                localStorage.removeItem("user");
                localStorage.removeItem("userId");
                localStorage.removeItem("userRoleId");
                reject(err);
            });
    });
};

export const logout = ({ commit }) => {
    return new Promise(resolve => {
        commit("logout");
        localStorage.removeItem("token");
        localStorage.removeItem("tokenExpirationDate");
        localStorage.removeItem("user");
        localStorage.removeItem("userId");
        localStorage.removeItem("userRoleId");
        delete axios.defaults.headers.common["Authorization"];
        resolve();
    });
};

export default {
    login,
    logout
};
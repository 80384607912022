<template>
    <v-app>
        <v-app-bar v-if="showMenu" app color="primary" dark>
            <div class="d-flex align-center">
                <v-toolbar-title to="/">
                    Nuform Sales Opportunities v.0.35
                </v-toolbar-title>
            </div>
            <v-spacer></v-spacer>
            <v-btn class="mr-3" text to="/home">
                <span>home</span>
            </v-btn>
            <v-btn v-if="isAdmin" text to="/admin">
                <span>admin</span>
            </v-btn>
            <v-btn text to="/profile">
                <span>profile</span>
            </v-btn>
            <v-btn text v-on:click="logout">
                <span>logout</span>
            </v-btn>
        </v-app-bar>
        <v-content>
            <router-view></router-view>
        </v-content>
    </v-app>
</template>

<script>
    export default {
        name: 'App',
        components: {},
        computed: {
            showMenu() {
                return this.$route.name !== "login";
            },
            isAdmin() {
                return localStorage.getItem("userRoleId") == 1;
            }
        },
        data: () => ({
        }),
        methods: {
            logout: function () {
                this.$store.dispatch("logout").then(() => {
                    this.$router.push("/login");
                });
            }
        },
        created: function () {
            // Handle expired tokens
            this.$http.interceptors.response.use(undefined, function (err) {
                return new Promise(function () {
                    if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
                        this.$store.dispatch("logout");
                    }
                    throw err;
                });
            });
        },
    };
</script>


// noinspection ES6UnusedImports
import Vue from 'vue'
import axios from 'axios'
import store from '../store'

//axios.defaults.baseURL = "https://localhost:5001/api/"
//axios.defaults.baseURL = "https://172.19.1.195:5001/api/"
axios.defaults.baseURL = "https://sales.nuformdirect.com:5001/api/"
axios.defaults.headers.common.Accept = 'application/json'

const token = localStorage.getItem("token");
if (token) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
}

axios.interceptors.response.use(
    response => response,
    error => {
        console.log(error);
        if (error.response.status === 401) {
            return new Promise(() => {
                store.dispatch("logout").then(() => {
                    location.reload();
                });
            });
        }
        return Promise.reject(error);
    }
);

Vue.$http = axios;
Object.defineProperty(Vue.prototype, "$http", {
    get() {
        return axios;
    }
});
import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import store from './store'

Vue.use(VueRouter)

const router = new VueRouter({ routes })

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth == false)) {
        next();
    } else {
        if (store.getters.isLoggedIn) {
            next();
            return;
        }
        next("/login");
    }
});

export default router